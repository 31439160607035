<template>
	<LayoutOffcanvasStandard ref="offcanvas" title="Opciones de la búsqueda" id="search-modal-option">
		<template #body>
			<div class="list-group list-group-flush gap-5">
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('edit') && search.pivot?.owner">
					<div>
						<a href="javascript:void(0)" @click="edit" class="d-block h5 fw-bold stretched-link">{{ search.title }}</a>
						<p class="text-sm lh-xs">Creada {{ createdAtDiff }}</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-pencil-square"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('list')">
					<div>
						<a href="javascript:void(0)" @click="list" class="d-block h5 fw-bold stretched-link">Ver publicaciones filtradas</a>
						<p class="text-sm lh-xs">
							<template v-if="count === null">Se mostrará el listado de publicaciones</template>
							<template v-else-if="count == 0">No hay publicaciones</template>
							<template v-else-if="count == 1">Hay 1 publicación</template>
							<template v-else>Hay {{ count }} publicaciones</template>
						</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('share') && search.pivot?.owner">
					<div>
						<a href="javascript:void(0)" @click="share" class="d-block h5 fw-bold stretched-link">Compartir búsqueda</a>
						<p class="text-sm lh-xs">Comparte la búsqueda con otras personas</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('send')">
					<div>
						<a href="javascript:void(0)" @click="send" class="d-block h5 fw-bold stretched-link">Enviar listado</a>
						<p class="text-sm lh-xs">De las publicaciones que seleccionaste</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('finish') && !search.pivot?.owner">
					<div>
						<a href="javascript:void(0)" @click="finish" class="d-block h5 fw-bold stretched-link">Finalizar selección</a>
						<p class="text-sm lh-xs">Avisaremos que has terminado</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item hstack gap-5 border-0 p-0" v-if="!hidden.includes('publish') && search.pivot?.owner && search.group_id">
					<div>
						<a href="javascript:void(0)" @click="publish" class="d-block h5 fw-bold stretched-link">Publicar búsqueda</a>
						<p class="text-sm lh-xs">Para que la vean los integrantes del grupo</p>
					</div>
					<div class="ms-auto text-end align-self-center">
						<i class="bi bi-chevron-right"></i>
					</div>
				</div>
				<div class="list-group-item border-0 p-0" v-if="!hidden.includes('guide')">
					<a href="javascript:void(0)" @click="guide" class="link-warning fw-bold"><i class="bi bi-info-circle me-1"></i>¿Cómo funcionan las búsquedas?</a>
				</div>
			</div>
		</template>
	</LayoutOffcanvasStandard>

	<SearchModalShare ref="modalShare" :search="search" :vieweds="vieweds" @likeAll="likeAll" />
	<SearchModalEdit ref="modalEdit" :search="search" v-if="search?.pivot?.owner" />
	<SearchModalMap ref="modalMap" :search="search" :vieweds="vieweds" @display="display" />
	<SearchModalPublic ref="modalPublic" :search="search" />
</template>

<script>
	import { ref, computed, getCurrentInstance } from 'vue';
	import router from '@/router';
	import store from '@/store';
	import moment from 'moment';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		emits: ['display', 'guide', 'likeAll'],
		props: {
			search: Object,
			count: {
				type: Number,
				default: null
			},
			vieweds: {
				type: Array,
				default: []
			},
			hidden: {
				type: Array,
				default: []
			}
		},
		setup(props, { emit }) {
			const instance = getCurrentInstance();
			const { catchError } = composableForm();
			const hasUser = computed(() => store.getters['auth/hasUser']);

			const modalShare = ref(null);
			const modalEdit = ref(null);
			const modalMap = ref(null);
			const modalPublic = ref(null);
			const offcanvas = ref(null);
			const show = () => offcanvas.value.offcanvas.show();

			const createdAtDiff = computed(() => moment(props.search?.created_at).fromNow());

			const login = () => instance.proxy.$identify({ subtitle: 'Para utilizar las búsquedas' });

			const list = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();

				router.push({ name: 'account.search.filtered', params: { uuid: props.search.uuid ?? null }});
			}

			const share = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();

				modalShare.value.show();
			}

			const edit = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();
				else if(!props.search?.pivot?.owner) return toast.error('Para realizar esta acción debes haber creado esta búsqueda.');

				modalEdit.value.show();
			}

			const publish = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();
				else if(!props.search?.pivot?.owner) return toast.error('Para realizar esta acción debes haber creado esta búsqueda.');
				else if(!props.search?.group_id) return toast.error('No es posible realizar esta acción.');

				modalPublic.value.show();
			}

			const send = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();
				else if(!navigator.canShare) return toast.error(`Tu dispositivo no permite compartir contenido.`);
				else SearchRepository.text(props.search.uuid).then((response) => {
					navigator.share({
						title: 'Mis publicaciones favoritas',
						text: response.data.text
					});
				}).catch((e) => catchError(e, true));
			}

			const finish = async () => {
				await offcanvas.value.offcanvas.hide();
				if(!hasUser.value) return login();

				SearchRepository.finish(props.search.uuid).then((response) => {
					router.push({ name: 'account.search.filtered', params: { uuid: props.search.uuid ?? null }});
				}).catch(() => console.error('Join search error...'));
			}

			const display = (id) => emit('display', id);
			const guide = async () => {
				await offcanvas.value.offcanvas.hide();
				emit('guide');
			}

			const likeAll = (results) => {
				emit('likeAll', results);
			}

			return { modalShare, modalEdit, modalMap, modalPublic, offcanvas, login, show, login, list, share, edit, publish, send, finish, display, guide, createdAtDiff, likeAll };
		}
	}
</script>