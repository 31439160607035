import Repository from './Repository';
import { useToast } from 'vue-toastification';

const resource = '/search';
const toast = useToast();

export default {
	store(payload) {
		return Repository.post(`${resource}`, payload);
	},
	update(uuid, payload) {
		return Repository.put(`${resource}/${uuid}`, payload).then((response) => {
			toast.success(`Búsqueda actualizada correctamente.`);
			return response;
		});
	},
	show(uuid, params) {
		return Repository.get(`${resource}/${uuid}`, { params: params });
	},
	publish(uuid, payload) {
		return Repository.put(`${resource}/${uuid}/publish`, payload).then((response) => {
			if(payload.public) toast.success(`La búsqueda fué publicada.`);
			else toast.success(`La búsqueda dejó de estar publicada.`);

			return response;
		});
	},
	results(uuid, payload) {
		return Repository.post(`${resource}/${uuid}/results`, payload);
	},
	result(uuid, payload) {
		return Repository.post(`${resource}/${uuid}/result`, payload);
	},
	markers(uuid) {
		return Repository.post(`${resource}/${uuid}/markers`);
	},
	filtereds(uuid, payload) {
		return Repository.post(`${resource}/${uuid}/filtereds`, payload);
	},
	count(uuid) {
		return Repository.get(`${resource}/${uuid}/count`);
	},
	predictCount(uuid, payload) {
		return Repository.post(`${resource}/${uuid}/predict/count`, payload);
	},
	predictSelect(uuid, payload) {
		return Repository.post(`${resource}/${uuid}/predict/select`, payload).then((response) => {
			if(response.data.results.length == 0) toast.error(`No se seleccionaron publicaciones.`);
			else if(response.data.results.length == 1) toast.success(`Se seleccionó una publicación.`);
			else if(response.data.results.length > 1) toast.success(`Se seleccionaron ${response.data.results.length} publicaciones.`);

			return response;
		});
	},
	text(uuid) {
		return Repository.get(`${resource}/${uuid}/text`);
	},
	join(uuid) {
		return Repository.post(`${resource}/${uuid}/join`);
	},
	finish(uuid) {
		return Repository.post(`${resource}/${uuid}/finish`).then((response) => {
			toast.success(`Se envió un aviso de que tu selección fué finalizada.`);
			return response;
		});
	},
	decision(id, payload) {
		return Repository.post(`${resource}/${id}/decision`, payload);
	},
	undo(id, payload) {
		return Repository.post(`${resource}/${id}/undo`, payload);
	}
}