<template>
	<div class="card rounded-4">
		<div class="card-body p-4 hstack gap-4 align-items-center">
			<div class="flex-fill vstack gap-1">
				<h5 v-if="!predicted"><i class="bi bi-magic me-1"></i>Incluir predicciones</h5>
				<h5 class="text-success" v-else><i class="bi bi-check-lg me-1"></i>Predicciones incluídas</h5>
				<div class="text-sm lh-sm text-body-secondary" v-if="loading">Obteniendo información de predicciones...</div>
				<div class="text-sm lh-sm text-body-secondary" v-else-if="count == 0">No se encontraron publicaciones que cumplan con tu selección</div>
				<div class="text-sm lh-sm text-body-secondary" v-else-if="count == 1">Se encontró una publicación que cumple con tu selección</div>
				<div class="text-sm lh-sm text-body-secondary" v-else>Se encontraron {{ count }} publicaciones que cumplen con tu selección</div>
			</div>
			<button type="button" class="btn btn-sm btn-neutral rounded-pill" :disabled="submitting || predicted || count <= 0" @click="submit">Incluir</button>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import _ from 'lodash';
	import composableForm from '@/composables/form';
	import SearchRepository from '@/repositories/SearchRepository';

	export default {
		emits: ['likeAll'],
		props: {
			search: Object,
			vieweds: {
				type: Array,
				default: []
			}
		},
		setup(props, { emit }) {
			const { loading, submitting, catchError } = composableForm();
			const predicted = ref(false);
			const count = ref(null);

			const getPredict = () => {
				if(props.search.uuid) SearchRepository.predictCount(props.search.uuid, { except: props.vieweds }).then((response) => {
					count.value = response.data.count;
				}).catch((e) => {
					catchError(e, true);
				}).finally(() => {
					loading.value = false;
				});
			}

			const submit = () => {
				submitting.value = true;

				if(props.search.uuid) SearchRepository.predictSelect(props.search.uuid, { except: props.vieweds }).then((response) => {
					emit('likeAll', response.data.results);
					predicted.value = true;
				}).catch((e) => {
					catchError(e, true);
				}).finally(() => {
					submitting.value = false;
				});
			}

			onMounted(() => {
				getPredict();
			});

			return { loading, submitting, predicted, count, submit };
		}
	}
</script>