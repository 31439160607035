<template>
	<teleport to="body">
		<div :id="id" class="offcanvas offcanvas-bottom rounded-top-5 rounded-top-md-0 border-2 border-dark overflow-hidden" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
			<div class="offcanvas-header" :class="headerClass" v-if="title || subtitle">
				<div class="vstack">
					<div class="h3 offcanvas-title" v-if="title">{{ title }}</div>
					<div class="text-muted lh-xs" v-if="subtitle">{{ subtitle }}</div>
				</div>
				<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body" :class="bodyClass">
				<slot name="body" />
			</div>
			<div class="offcanvas-footer p-6 pt-2" :class="footerClass" v-if="$slots.footer">
				<slot name="footer" />
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import { Offcanvas } from 'bootstrap';

	export default {
		props: {
			id: String,
			title: String,
			subtitle: String,
			headerClass: {
				type: String,
				default: 'container-md pb-2'
			},
			bodyClass: {
				type: String,
				default: 'container-md vstack gap-5'
			},
			footerClass: {
				type: String,
				default: 'container-md hstack gap-3 justify-content-end'
			}
		},
		setup(props) {
			const offcanvas = ref(null);
			const opened = ref(false);

			onMounted(() => {
				offcanvas.value = new Offcanvas(document.getElementById(props.id));

				document.getElementById(props.id).addEventListener('show.bs.offcanvas', () => {
					opened.value = true;
				});

				document.getElementById(props.id).addEventListener('hide.bs.offcanvas', () => {
					opened.value = false;
				});
			});

			return { offcanvas, opened };
		}
	}
</script>